// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-about-page-js": () => import("../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-privacy-policy-js": () => import("../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-quit-smoking-page-js": () => import("../src/templates/quit-smoking-page.js" /* webpackChunkName: "component---src-templates-quit-smoking-page-js" */),
  "component---src-templates-stress-management-page-js": () => import("../src/templates/stress-management-page.js" /* webpackChunkName: "component---src-templates-stress-management-page-js" */),
  "component---src-templates-unwanted-habits-page-js": () => import("../src/templates/unwanted-habits-page.js" /* webpackChunkName: "component---src-templates-unwanted-habits-page-js" */),
  "component---src-templates-weightloss-page-js": () => import("../src/templates/weightloss-page.js" /* webpackChunkName: "component---src-templates-weightloss-page-js" */),
  "component---src-templates-your-success-page-js": () => import("../src/templates/your-success-page.js" /* webpackChunkName: "component---src-templates-your-success-page-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-sitemap-js": () => import("../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */)
}

